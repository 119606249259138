import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { COLORS } from "../styles/constants"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '5rem 0'}}>
      <div style={{ maxWidth: '1200px', width: '100%' }}>
        <h1>Page not found</h1>
        <p style={{ color: COLORS.gray }}>Go back <Link to={'/'}>home</Link>.</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
